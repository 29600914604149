
<template>
<div class="container-fluid mt-3">
 
      <div class="tw-flex tw-justify-end">
        <div class="tw-flex tw-cursor-pointer tw-border tw-rounded tw-border-gray-n2 
            tw-text-sm tw-items-center  tw-p-2" @click="inviteModal = true">
          <span class="tw-flex">
            <send-icon class="tw-w-5 tw-h-5 tw-mr-2" />
            {{$t('dashboard.menu.public_ratings')}}
          </span>
        </div>
      </div>


    <v-card elevation="0">
    
                <v-app-bar
                elevation="0"
                color="white"
                >
            
                <v-spacer></v-spacer>

                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    clearable
                    
                  ></v-text-field>

            </v-app-bar>

     
      <v-card-text>
     
        <v-data-table
          dense
          :show-expand="true"
          :expanded.sync="expanded"
          :headers="headers"
          :items="data"
          :search="search"
          :server-items-length="this.meta.total"
          :options.sync="options"
          class="ml-table"
          :loading="loading" loading-text="Loading... Please wait"
          :footer-props="{
            'items-per-page-options': [15],
            disableItemsPerPage : true,
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus'
          }"
        >

    <template v-slot:expanded-item="{headers, item }">
      <td v-if="!item.rating && item.pending" :colspan="headers.length">
                <table class="table">
                  <tr>
                    <th>Message to client</th>
                    <th>Notice</th>
                    <th>Status</th>
                    <th>Nr.</th>
    
                  </tr>
                  <tbody>
            
                    <tr>
                      <td v-html="item.body">
               
                      </td>
                      <td>
                        <v-btn outlined color="blue" rounded small @click="update(item)">
                          Send message
                        </v-btn>
                      </td>
                      <td>
                        <span class="text-xs font-weight-light"
                          :class="item.pending ? 'blue--text':'green--text'">
                          {{ item.pending ? 'Pending':'Rated' }}
                        </span>
                      </td>
                      <td>{{item.number_of_notifications}}</td>
                    </tr>
                
                  </tbody>
                </table>
      </td>
      <td v-else-if="item.rating" :colspan="headers.length">
          {{item.rating.body}}
      </td>
      <td v-else>
      </td>
    </template>



        <template  v-slot:[`item.order`]="{ item }">
          <div v-if="item.order">
            <span>{{item.order.id}} | <span v-if="item.order.status">{{item.order.status.name}}</span></span>
          </div>
        </template>

        <template  v-slot:[`item.rating`]="{ item }">
                  <div v-if="item.rating">
                    <v-rating
                        :value="item.rating.rating"
                        color="red"
                        background-color="red"
                        class="tw-flex"
                        dense
                        emptyIcon='mdi-heart-outline'
                        fullIcon='mdi-heart'
                        halfIcon='mdi-heart-half-full'
                        half-increments
                        readonly
                        size="15"
                     ></v-rating>
                  </div>
            
        </template>
        <template  v-slot:[`item.date_posted`]="{ item }">
                  <div v-if="item.rating">
                    <span>{{item.rating.created_at}}</span>
                  </div>
        </template>
        <template  v-slot:[`item.created_by`]="{ item }">
              <v-avatar size="32">
              <v-img :src="item.user.avatar"></v-img>
            </v-avatar>
            <span class="ml-2">{{item.user.name}}</span>
        </template>
        
         <template  v-slot:[`item.comment`]="{ item }">
          <div v-if="item.rating">
             <span>{{item.rating.body | truncate(150)}}</span>
          </div>
        </template>
 

            <!--
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="item.status != 'Approved' && item.status != 'Rejected'"
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            
            <v-icon
              v-if="item.status != 'Approved' && item.status != 'Rejected'"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
           
          </template>
           -->
      
    

        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
        v-model="inviteModal"
        max-width="600"
    >
      <InviteToRate @close="inviteModal = false" @invitedForNewReview="invitedForNewReview"/>
    </v-dialog>
</div>
</template>


<script>
import i18n from '@/i18n'
import InviteToRate from "@/components/InviteToRate";
import { SendIcon  } from "vue-feather-icons";

export default {
  middleware: ['auth','vendor'],
  components: {
    InviteToRate,SendIcon 
  },
  metaInfo(){
    return {
      title:i18n.t('ratings'),
      meta:[
        {
          name: 'description',
          content: i18n.t('description.vendors/ratings'),
        },
      ]
    }
  },
  data () {
      return {
        inviteModal: false,
        showPrint: false,
        selectedItem: null,
        singleExpand: false,
        expanded: [],
        response: [],
        dialog: false,
        editedIndex: -1,
        editedItem: {
          status: ''
        },
        defaultItem: {
          status: ''
        },
        loading: false,
        data: [],
        meta: {
          total:0
        },

        search: '',
        options: {},
        headers: [
          { text: 'Order #', value: 'order',sortable: false },
          {
            text: 'Rating',
            align: 'start',
            sortable: false,
            value: 'rating',
            width: 300
          },          
          { text: 'Date Posted', value: 'date_posted',sortable: false },
          { text: 'Raiting From', value: 'created_by',sortable: false },
          { text: 'Review', value: 'comment'}
        ],
      }
    },
    mounted() {
     // this.getItems();
    },
    watch: {
          options: {
           async  handler () {
              await this.getItems()
            },
            deep: true,
          },
          meta: function(){
            //this.options.page = this.meta.current_page;
            //this.options.itemsPerPage = this.meta.per_page;
            ///:items-per-page="meta.per_page"
          },
          search: function(){
            if(this.search == null || this.search == "") {
              this.getItems();
            }
            if (this.search.length < 3) return
            this.getItems()
          }          
        },    
    methods: {
      invitedForNewReview(){
        this.getItems();
      },
      onClearClicked () {
        this.search = null;
        this.getItems();
      },
      print(item){
        //console.log(item);
        this.selectedItem = item;
        this.showPrint = true;
      },
      getColor (satus) {
        if (satus === 'Open') return 'cyan'
        else if (satus === 'Offered') return 'orange'
        else if (satus === 'Accepted') return 'green'
        else return 'red'
      },
      getColorClass (satus) {
        if (satus === 'Open') return 'yellow--text'
        else if (satus === 'Approved') return 'black--text'
        else if (satus === 'Pending') return 'orange--text'
         else if (satus === 'Processed') return 'blue--text'
          else if (satus === 'Delivered') return 'green--text'
        else return 'red--text'
      },
      getItems(){
        this.loading = true;

        let sortDesc = this.options.sortDesc[0];
        let orderby = sortDesc ? '-'+this.options.sortBy[0]:this.options.sortBy[0];
        

        const params = 
          {
                "filter[search_data]" : this.search,
                "page": this.$route.query.page ? this.$route.query.page:this.options.page,
                "sort" : orderby ? orderby:this.$route.query.sort,     
          };

        let uri = 'api/rating/collections';
        this.$http.get(uri,{ params: params }).then((response) => {
            this.data = response.data.data,
            this.meta = response.data.meta,
            this.loading = false
        });
      },
      sendMessage(item){

              let uri = '/api/rating/collections/' + item.id;
                this.$http.patch(uri,item)
                .then((response) => {
                  this.$toast.success(response.data.message, "Success")
                })
                .catch(error => {
                    this.$toast.warning(error.data.message, "Warning")
                }).finally(() => 
                  this.getItems()
                )
      },
      update(item) {
                this.$toast.question('Are you sure about that?', "Confirm", {
                timeout: 20000,
                close: false,
                overlay: true,
                displayMode: 'once',
                id: 'address',
                zindex: 999,
                title: 'Hey',            
                position: 'center',
                buttons: [
                    ['<button><b>YES</b></button>', (instance, toast) => {
                        
                        this.sendMessage(item);

                        instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

                    }, true],
                    ['<button>NO</button>', function (instance, toast) {

                        instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

                    }],
                ]            
                });            
      },
    },

}
</script>







